import * as React from "react";
import meredith from "../../../static/img/t_meredith-min.jpg";
import myra from "../../../static/img/t_myra-min.jpg";
import barbara from "../../../static/img/t_barbara-min.jpg";
import cassie from "../../../static/img/t_cassie-min.jpg";
import erica from "../../../static/img/t_erica-min.jpg";
import sandy from "../../../static/img/t_sandy-min.jpg";
import tegan from "../../../static/img/t_tegan-min.jpg";
import tiffany from "../../../static/img/t_tiffany-min.jpg";
import becca from "../../../static/img/t_becca-min.jpg";

export default function Testimonials() {
  return (
    <div className="signup-testimonials">
      <div className="container">
        <div className="inner-box">
          <div className="testimonial-content">
            <div className="avatar">
              <img src={erica} alt="Testimonial by Erica" />
            </div>
            <div className="body">
              Kristie is the best! She eats, sleeps and breathes balance! You
              just can't help but get energized by her. Her fast, yet effective
              30-minute at home workouts have been a lifesaver! With a 5 month
              old it's pretty hard to make it to a gym these days and let's be
              honest the last thing you want to do after getting home from work
              is leave your baby again. Signing up was the best thing I ever did
              for myself!
            </div>
            <div className="name">- Erica H</div>
          </div>
        </div>
        <div className="inner-box">
          <div className="testimonial-content">
            <div className="avatar">
              <img src={barbara} alt="Testimonial by Barbara" />
            </div>
            <div className="body">
              Kristie is very knowledgeable about nutrition and exercise. She
              works hard to help you develop those healthy habits so that you
              will not only lose the weight,t but keep it off! She truly helps
              you to not diet, but to be able to eat what you need to fuel your
              body and to be able to sustain your eating habits. The weekly
              workouts are tough but she shows you how to modify them. At 60
              years old, this is the first time I have been able to exercise at
              home! You won't be disappointed with Kristie! She truly cares and
              wants everyone to progress and develop a healthy lifestyle.
            </div>
            <div className="name">- Barbara K</div>
          </div>
        </div>
        <div className="inner-box">
          <div className="testimonial-content">
            <div className="avatar">
              <img src={tegan} alt="Testimonial by Tegan" />
            </div>
            <div className="body">
              #Balance has not only helped me physically, but also mentally!
              I've totally changed the way I look at and think about myself!
              From the weekly #balance banters and all the videos showing how to
              do the exercises and how to modify the exercises to the amazing
              Facebook group. I've never had so much success from a workout
              program in the past!
            </div>
            <div className="name">- Tegan M</div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="inner-box">
          <div className="testimonial-content">
            <div className="avatar">
              <img src={sandy} alt="Testimonial by Sandy" />
            </div>
            <div className="body">
              Kristie's program is easy to follow, challenging but great! And
              her support is literally impossible to beat. The cost is a
              fraction of what others charge for not near the quality! I made a
              life change with Kristie Lengel and am so happy for it!
            </div>
            <div className="name">- Sandy G</div>
          </div>
        </div>
        <div className="inner-box">
          <div className="testimonial-content">
            <div className="avatar">
              <img src={tiffany} alt="Testimonial by Tiffany" />
            </div>
            <div className="body">
              Kristie's workouts are a lifesaver. Her program has given me the
              balance I needed to continue working toward my fitness goals while
              running a business and managing my children's activities. The
              program is simple to navigate and the workout plans/videos are
              easy to follow. I also love that she is available for personal
              meetings when I need some extra support! Kristie knows her stuff.
            </div>
            <div className="name">- Tiffany F</div>
          </div>
        </div>
        <div className="inner-box">
          <div className="testimonial-content">
            <div className="avatar">
              <img src={meredith} alt="Testimonial by Meredith" />
            </div>
            <div className="body">
              Kristie's weekly work-outs are terrific! Easy to follow - hard
              enough to feel like you've had your trainer in the house with you
              - and flexible enough to do when you can and take them on the go!
              Plus, she has an awesome balanced-life approach! I don't get to
              workout everyday, but I have a week's worth of workouts I can do
              on my time...so nice not to have to think of what to do myself!
              Thanks Kristie!!
            </div>
            <div className="name">- Meredith C</div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="inner-box">
          <div className="testimonial-content">
            <div className="avatar">
              <img src={becca} alt="Testimonial by Becca" />
            </div>
            <div className="body">
              I would 100% recommend Kristie's workouts to everyone! Whether
              you're just starting out or are a fitness enthusiast, Kristie's
              workouts will make you sweat and burn in the best ways! I love the
              fact that no two workouts are the same and that you can complete
              them in a 30-40 minute time frame because life is busy and I don't
              have all day to spend at the gym! Kristie genuinely cares about
              her clients and wants to see them succeed! She will kick your butt
              and be your biggest cheerleader at the same time! Also I love her
              #balance banter videos and her stance on finding #Balance in life
              between your fitness journey and still enjoying life because life
              is too short not to enjoy it!
            </div>
            <div className="name">- Becca E</div>
          </div>
        </div>

        <div className="inner-box">
          <div className="testimonial-content">
            <div className="avatar">
              <img src={cassie} alt="Testimonial by Cassie" />
            </div>
            <div className="body">
              Kristie is an amazing coach, not only for exercise but for
              nutrition as well! I had a goal in mind, she figured out exactly
              what and how I needed to be eating to get there and I was able to
              achieve it. HIGHLY recommend Kristie!!
            </div>
            <div className="name">- Cassie C</div>
          </div>
        </div>
        <div className="inner-box">
          <div className="testimonial-content">
            <div className="avatar">
              <img src={myra} alt="Testimonial by Myra" />
            </div>
            <div className="body">
              Kristie knows what she is talking about! She can help anyone with
              their fitness goals and I am so happy she came into my life! Her
              workouts are challenging and easy to follow. She gives
              instructional videos on every move and is always available to
              answer questions!
            </div>
            <div className="name">- Myra J</div>
          </div>
        </div>
      </div>
    </div>
  );
}
